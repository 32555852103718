import styles from './FreeBetsTool.module.css';

export const FreeBetsToolAnimation = () => {
	return (
		<>
			<div className={styles.Circles}>
				<div className={styles.Circle1}>
				</div>
			</div>
		</>
	);
};
