import styles from './FreeBetsTool.module.css';
import { FreeBetsToolAnimation } from './FreeBetsTool.Animation';
import Loading_Logo from './Loading_Logo.png';
import { useState } from 'react';

export enum HTTPMethod {
	POST = 'POST',
	GET = 'GET',
	PUT = 'PUT',
	DELETE = 'DELETE',
}

export enum Header {
	ACCEPT = 'Accept',
	CONTENT_TYPE = 'Content-Type',
	AUTHORIZATION = 'Authorization',
}

export enum MediaType {
	APPLICATION_JSON = 'application/json',
}

export enum FreeBetsType {
	REGULAR = 'REGULAR',
	POWERBET = 'POWERBET',
	BUYFEATURE = 'BUYFEATURE',
	SPINONLYWIN = 'SPINONLYWIN',
}

export interface RequestPayload {
	sessionId: string;
	type: FreeBetsType;
	spins: number;
	bet: number;
}

function getStandardHeaders(jwt?: string) {
	const standard: any = {};
	standard[Header.ACCEPT] = MediaType.APPLICATION_JSON;
	standard[Header.CONTENT_TYPE] = MediaType.APPLICATION_JSON;

	if (jwt) standard[Header.AUTHORIZATION] = `Bearer ${jwt}`;

	return standard;
}

async function sendFetch(url: string, payload: RequestPayload) {
	const body = {
		sessionId: payload.sessionId,
		type: payload.type,
		featureCode: payload.type,
		rtpVersion: 94,
		betAmount: payload.bet * 100,
		betCount: payload.spins,
	};

	return new Promise((resolve: (response: any) => void) => {
		fetch(url + '/givefreebet', {
			method: HTTPMethod.POST,
			headers: getStandardHeaders(),
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then((response) => {
				alert(JSON.stringify(response));
			})
			.catch((error) => {
				alert('Error: ' + JSON.stringify(error));
			});
	});
}

export const FreeBetsTool = () => {
	const [url, setUrl] = useState('');
	const [id, setid] = useState('');
	const [spins, setSpins] = useState(0);
	const [bet, setBet] = useState(0);
	const [type, setType] = useState(FreeBetsType.BUYFEATURE);

	const payload: RequestPayload = {
		sessionId: id,
		type: type,
		spins: spins,
		bet: bet,
	};

	return (
		<>
			<FreeBetsToolAnimation />
			<div className={styles.Main}>
				<img alt="logo" className={styles.LogoImage} src={Loading_Logo}></img>

				<h2>Free Bets Tool</h2>

				<h3>Server</h3>
				<input className={styles.InputText} type="text" value={url} onChange={(e) => setUrl(e.target.value)} />

				<h3>Session</h3>
				<input className={styles.InputText} type="text" value={id} onChange={(e) => setid(e.target.value)} />

				<h3>Type</h3>
				<select
					className={styles.Selector}
					value={type}
					onChange={(e) => setType(e.target.value as FreeBetsType)}
				>
					<option value={FreeBetsType.REGULAR}>{FreeBetsType.REGULAR}</option>
					<option value={FreeBetsType.POWERBET}>{FreeBetsType.POWERBET}</option>
					<option value={FreeBetsType.BUYFEATURE}>{FreeBetsType.BUYFEATURE}</option>
					<option value={FreeBetsType.SPINONLYWIN}>{FreeBetsType.SPINONLYWIN}</option>
				</select>

				<h3>Amount</h3>
				<input
					className={styles.InputAmount}
					type="number"
					value={spins}
					onChange={(e) => setSpins(Number(e.target.value))}
				/>

				<h3>Bet</h3>
				<input
					className={styles.InputAmount}
					type="number"
					step="0.01"
					value={bet}
					onChange={(e) => setBet(Number(e.target.value))}
				/>

				<div className={styles.Line}></div>

				<div className={styles.ButtonsContainer}>
					<button
						className={styles.Button}
						onClick={() => {
							console.log('SEND at:', url);
							console.log('object:', url);

							sendFetch(url, payload);
						}}
					>
						Send
					</button>
				</div>
			</div>
		</>
	);
};
