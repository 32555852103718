import './App.css';
import { Routes, Route, HashRouter } from 'react-router-dom';
import { AboutPage } from './pages/aboutPage/AboutPage';
import { HomePage } from './pages/homePage/HomePage';
import { ContactPage } from './pages/contactPage/ContactPage';
import { ProjectsPage } from './pages/projectsPage/ProjectsPage';
import { YugoDevPage } from './pages/yugoPage/YugoDevPage';
import { FreeBetsTool } from './pages/freeBetsToolPage/FreeBetsTool';

function App() {
	console.clear();

	return (
		<HashRouter>
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/contact' element={<ContactPage />} />
				<Route path='/about' element={<AboutPage />} />
				<Route path='/projects' element={<ProjectsPage />} />

				<Route path='/yugo' element={<YugoDevPage />} />
				<Route path='/yugo_free_bets_tool' element={<FreeBetsTool />} />

			</Routes>
		</HashRouter>
	);
}

export default App;
